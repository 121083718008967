import React from "react"

// Libraries
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Glide from "@glidejs/glide"

// Stylesheets
import "@glidejs/glide/dist/css/glide.core.min.css"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

const StyledTestimonials = styled.section`
display: flex;
-webkit-box-align: center;
align-items: center;
margin: 80px 0 0 0;
padding: 60px 0;
border-bottom-width: 0;
color: ${colors.charcoal};

	${breakpoint.medium`
		padding: 100px 0 120px;
	`}

  .bullets {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 1rem;

	${breakpoint.small`
		margin: 2rem 0 1rem;
	`}

		
    .bullet {
      width: 13px;
      height: 13px;
      position: relative;
      display: flex;
      margin: 0px 3px 0px 4px;
      border-radius: 50%;
      transition: all 0.3s;
      cursor: pointer;

      &::before {
        content: "";
        width: 7px;
        height: 7px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        border: 1px solid ${colors.orange};
        border-radius: 50%;
      }

      &:hover,
      &.glide__bullet--active {
        background-color: ${colors.orange};
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
`

const StyledTestimony = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    columns: 2;
    column-gap: 50px;
    place-content: center;
    padding: 0px;

  .testimony__content {
    max-width: 900px;
    position: relative;
    margin-bottom: 20;
    z-index: 10;
    flex-basis: 450px;
    flex-grow: 1;
    flex-shrink: 1;

    .testimony {
      position: relative;
      margin-bottom: 1.1rem;
      margin-top: 0;

      &::before {
        content: "“";
      }
		&::after {
        content: "” ";
      }

   }

    .author {
    	margin-top: 2em;
		max-width: 470px;
		font-size: 15px;
        font-weight: 600;
        line-height: 1.4em;

      ${breakpoint.medium`
			font-size: 19px;
      `}

		.position {
			padding-bottom: 1rem;
		}
    }

  }

	 .gatsby-image-wrapper {
		height: 440px;

      ${breakpoint.small`
			height: 500px;
      `}
	}    
 

  .testimony__image {
    margin: 0 40px 40px 0;
    max-width: 400px;
    flex-basis: 350px;
    flex-grow: 1;
    flex-shrink: 1;
    align-self: flex-start;
    width: 100%;
    position: relative;
    z-index: 10;
    

    &::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 30px;
		left: 30px;
		background-image: radial-gradient(${colors.grayDark} 0.06em,transparent 0.06em),radial-gradient(${colors.grayDark} 0.06em,transparent 0.06em);
		background-size: 11px 11px !important;
		background-position: 0 0, 17px 17px;
		background-repeat: repeat;
		opacity: 0.3;
		z-index: -1;


      ${breakpoint.medium`
        top: 40px;
        left: 40px;
      `}
    }
  }
`

const Testimony = props => (
  <StyledTestimony>
    <div className="testimony__image">
      {props.image && <GatsbyImage image={getImage (props.image)} width={440} alt={props.author} />}
    </div>
    <div className="testimony__content">
      <p className="testimony">{props.testimony}</p>

      <div className="author">
        <p className="name">{props.author}</p>
        <p className="position">{props.position}</p>
      </div>
    </div>
  </StyledTestimony>
)

class Carousel extends React.Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
  }

  componentDidMount = () => {
    const carousel = new Glide(this.myRef.current, {
      autoplay: 12000,
      type: "carousel"
    })
    carousel.mount()
  }

  render = props => (
    <div className="glide" ref={this.myRef} data-aos="fade-up" data-aos-once="false">
      <div className="glide__track" data-glide-el="track">
        <div className="glide__slides">{this.props.children}</div>
        <div className="bullets glide__bullets" data-glide-el="controls[nav]">
          <button type="bullet" className="bullet" data-glide-dir="=0" aria-label="First testimonial"></button>
          <button type="bullet" className="bullet" data-glide-dir="=1" aria-label="Second testimonial"></button>
          <button type="bullet" className="bullet" data-glide-dir="=2" aria-label="Third testimonial"></button>
          <button type="bullet" className="bullet" data-glide-dir="=3" aria-label="Fourth testimonial"></button>
          <button type="bullet" className="bullet" data-glide-dir="=4" aria-label="Fifth testimonial"></button>
          <button type="bullet" className="bullet" data-glide-dir="=5" aria-label="Sixth testimonial"></button>
        </div>
      </div>
    </div>
  )
}

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpTestimonial(sort: {fields: testimonials___order, order: ASC}) {
        nodes {
          id
          name: title
          testimonials {
            testimony
            authorPosition
            authorsImage {
              localFile {
                childImageSharp {
                  gatsbyImageData 
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <StyledTestimonials>
      <Container data-aos="fade-up">
        <Carousel>
          {data.allWpTestimonial.nodes.map((testimonial) => (
            <div className="glide__slide" key={testimonial.id}>
              <Testimony
                author={testimonial.name}
                position={testimonial.testimonials.authorPosition}
                testimony={testimonial.testimonials.testimony}
                image={testimonial.testimonials.authorsImage.localFile}
              />
            </div>
          ))}
        </Carousel>
      </Container>
    </StyledTestimonials>
  )
}

export default Testimonials
