import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

const StyledTopIcons = styled.section`
	background: ${colors.grayLight};
	padding-bottom: 30px;

	${breakpoint.medium`
		padding: 30px 0 60px;
	`}
				
	${Container} {
		padding: 0 10px;
		border: none !important;

		${breakpoint.medium`
			padding: 0 28px;
		`}
  	}

	h2 {
		text-align: center;
		margin: 0 auto;
		padding: 4rem 0 1rem;

		${breakpoint.medium`
			padding: 4rem 0 2rem;
		`}
	}
	
	.content {
		margin: 0 auto;
		text-align: center;
		padding: 0 30px;

		p {
			margin: 0 0 1rem;
		}
	
	 }


	.thumbnails_row {
		display: flex;
		flex-wrap: nowrap;
		column-gap: 10px;
		justify-content: space-evenly;
		align-content: center;
		margin: 0px;
		padding-block: 1rem;

		${breakpoint.small`
			column-gap: 10px;
		`}
		
		${breakpoint.medium`
			padding-block: 2.5rem;
		`}

		.image_column { 
			width: 100%;
			
				${breakpoint.xs`
					max-width: 120px;
				`}

				${breakpoint.small`
					max-width: 150px;
				`}
				
				${breakpoint.medium`
					max-width: 180px;
				`}

			p {
				text-align: center;
			    font-weight: 200;			
				font-size: 0.6em;
				line-height: 1.3em;			

				${breakpoint.medium`
					font-size: 0.7em;
				`}
			}
		}

		.thumbnail_image { 
			width: 50px;
			margin: 10px auto 15px;

			${breakpoint.xs`
				width: 60px;
			`}
		
			${breakpoint.small`
				width: 80px;
			`}
		} 
		
		.thumbnail_cagefree {
			width: 55px;
			margin: 10px auto 10px;
			left: 10px;

			${breakpoint.xs`
				width: 65px;
				margin: 10px auto 12px;
				left: 11px;
			`}
		
			${breakpoint.small`
				margin: 8px auto 8px;
				width: 90px;
				left: 11px;
			`}
		} 
	
		sup {
			font-size: 0.45rem;			
		}

	}
`

const TopIcons = () => {

	return (
		<StyledTopIcons tabIndex="-1">
			<Container>
				<h2 data-aos="fade-up" data-aos-once="false">PCR Results in<br />Less Than 30 Minutes</h2>
				<div className="content" data-aos="fade-up" data-aos-once="false" data-aos-delay="100">
					<p>
						In a world where speed and accuracy in disease testing has become increasingly important, 
						the efficient protection of public health depends on the delivery of fast and reliable 
						infectious disease testing to affected communities as quickly as possible.
					</p>
					<p>
						The Visby Medical portable, instrument-free PCR test delivers <i>results in minutes</i>, not days, 
						making our PCR test kits a revolution in the molecular diagnostics industry.
					</p>
					<p>
						The following are five key benefits to using Visby PCR in your lab or clinic, right at the point of care.
					</p>
				</div>
				<div className="thumbnails_row" data-aos="fade-up" data-aos-once="false" data-aos-delay="200">
					<div className="image_column">
						<StaticImage
							src="../../../assets/images/homepage/topicons/speed-new.png"
							layout="fullWidth"
							alt="True PCR results in under 30 minutes"
							className="thumbnail_image"
						/>
						<p>True PCR results in under 30&nbsp;minutes</p>
					</div>
					<div className="image_column">
						<StaticImage
							src="../../../assets/images/homepage/topicons/portable.png"
							layout="fullWidth"
							alt="Portable, deployable, and scalable"
							className="thumbnail_image"
						/>
						<p>Portable, deployable, and scalable</p>
					</div>
					<div className="image_column">
						<StaticImage
							src="../../../assets/images/homepage/topicons/accuracy.png"
							layout="fullWidth"
							alt="Accuracy of 97% or greater"
							className="thumbnail_image"
						/>
						<p>PCR accuracy</p>
					</div>
					<div className="image_column">
						<StaticImage
							src="../../../assets/images/homepage/topicons/easytouse2.png"
							layout="fullWidth"
							alt="No instrument, no maintenance"
							className="thumbnail_image"
						/>
						<p>No instrument,<br />no maintenance</p>
					</div>
					<div className="image_column">
						<StaticImage
							src="../../../assets/images/homepage/topicons/cagefree.png"
							layout="fullWidth"
							alt="No capital investment, no service contracts"
							className="thumbnail_image"
						/>
						<p>No capital investment, no service contracts</p>
					</div>
				</div>

			</Container>
		</StyledTopIcons>
	)
}

export default TopIcons
