import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

// Section Images
import bgImage1 from "assets/images/homepage/threesections/sexual-health-test.jpg"
import bgImage2 from "assets/images/homepage/threesections/respiratory-health-test.jpg"


const StyledThreeSections = styled.section`
	margin: 0 auto;
	padding: 0;
  
  ${breakpoint.medium`
    padding: 30px 0 0;
  `}

    ${Container} {  
    	max-width: 100%; 
	}

	.leftBox, .rightBox {
  		background-color: rgba(255, 255, 255, 0.7);
		display: flex;
		flex-direction: column;
		top: 0px;
		left: 0px;
		right: auto;
		position: absolute;
		width: 100%;
		height: 100%;
		color: ${colors.charcoal};
    	justify-content: center;
    	text-align: center;
    	margin: 0 auto;
    	padding: 40px;
    	
		${breakpoint.small`
  			background-color: rgba(255, 255, 255, 0.5);
			width: 50%;
		`}

		${breakpoint.medium`
    		padding: 20px 3%;
		`}
    	
		${breakpoint.large`
    		padding: 20px 6%;
		`}
	 }
  
	.rightBox {
		right: 0px;
		left: auto;
	}
    
  	.darkPurple, .lightPurple  {
  		display: flex;
		background-color: ${colors.purpleDark};
		color: ${colors.white};
    	text-align: center;
    	max-height: 600px;
 		width: 100%;
		height: 100%;
    	margin: 0 auto;
    	padding: 20px;
    	justify-content: center!important;

		${breakpoint.xs`
			width: 50%;
		`}

		${breakpoint.medium`
    		padding: 20px 3%;
		`}
    	
		${breakpoint.large`
    		padding: 20px 6%;
		`}
 	}
  
  .lightPurple {
  	background-color: ${colors.purpleLightNew};
  }

  .parallaxBg1 {
	background: url(${bgImage1});
	background-size: cover;
	background-position-x: center;
	background-position-y: center;
	background-repeat: no-repeat;
	background-attachment: scroll;
	width: 100%;
	height: 100%;
	min-height: 400px;

	${breakpoint.small`
		min-height: 600px;
	`}

	${breakpoint.medium`
		background-attachment: fixed;
	`}
	}
}

.parallaxBg2 {
	background: url(${bgImage2});
	background-size: cover;
	background-position-x: center;
	background-position-y: center;
	background-repeat: no-repeat;
	background-attachment: scroll;
	width: 100%;
	height: 100%;
	min-height: 400px;

	${breakpoint.small`
		min-height: 600px;
	`}

	${breakpoint.medium`
		background-attachment: fixed;
	`}
	}
`

const StyledContainer = styled(Container)`

    h2 {
		padding: 0;
		margin: 0 0 0.8rem 0;
		
		${breakpoint.medium`
      		margin-bottom: 1.5rem;
		`}
    }
  
    p {
		font-size: 0.9em;
		line-height: 1.4em;
		margin: 0;
		padding: 0;
    }

	.grid-container {
		display: grid;
		width: 100%;
		max-width: 600px;
		grid-template-columns: 1fr 1fr;
		padding: 5px !important;
		row-gap: 1em;
		align-items: center;
		justify-items: baseline;
	}
	
	.grid-container-bottom {
		display: grid;
		grid-template-columns: 5fr 1fr;
		padding: 0 0 0 30px;
		grid-gap: 0.5em;
		width: 100%;
		max-width: 400px !important;
		padding: 15px !important;
		align-items: center;
	}
	
		.grid-text {
			font-size: 15px;
			
			${breakpoint.small`
				font-size: 18px;
			`}		
		}
		
		.feet {
			margin: 0px auto;
		}

		.inches {
			margin: 0px auto;
		}

		.illustration {
			width: 100%;
			align-self: end!important;
			max-width: 600px;
			grid-column: span 2;
		}

		.illustration-bottom {
			width: 100%;
			align-self: end!important;
			max-width: 300px;
		}

		.bird {
			max-width: 35px;
			
			${breakpoint.medium`
			  max-width: 50px;
			`}
		}

		.cage {
			max-width: 250px;

			${breakpoint.medium`
			  max-width: 300px;
			`}
		}

		.sub, .subWhite {
			font-style: italic;
			margin-top: 10px;
			line-height: 1.1em;
			font-size: x-small;
			color: ${colors.graySelect};

			${breakpoint.medium`
			  font-size: small;
			`}
		}
	
	 .subWhite {
		color: ${colors.white};
	}
		
`

const StyledSection = styled.div`
	position: relative;
	border-bottom-width: 0;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	min-height: 400px;

	${breakpoint.small`
		min-height: 600px;
	`}	
	
`

const StyledColoredSection = styled.div`
		position: relative;
		border-bottom-width: 0;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		max-width: 100%;

		${breakpoint.small`
			flex-direction: row;
		`}		
		

		.column {
			flex-direction: row;
			flex-basis: 100%;
			flex-wrap: wrap;
			flex: 1;
		    align-content: center;
			width: 100%;
			height: auto;
			max-width: 100%;
			min-height: 400px;

			${breakpoint.small`
				min-height: 600px;
			`}		
		}
  
`

const ThreeSections = props => {
	return (
		<StyledThreeSections>
			<StyledSection key="section1" className="parallaxBg1">
				<StyledContainer className="leftBox">
						<h2>PCR results to treat in the same visit. That’s a first!</h2>
						<p>
							Visby Medical reduced a ~6-day<sup>1</sup> central lab wait time down to under 30 minutes at the point-of-care.
							You can walk into the exam room with the answer, patients walk out with confidence. Patient callbacks and loss to follow up may become a thing of the past.
						</p>
						<p className="sub">1. <a href="https://journals.lww.com/stdjournal/Citation/2022/04000/Clinical_Integration_of_a_Highly_Accurate.3.aspx" target="_blank" rel="noreferrer noopener">STD Journal</a></p>
					</StyledContainer>
			</StyledSection>
			<StyledColoredSection key="section2">
				<StyledContainer className="column darkPurple">
					<div className="grid-container" data-aos="slide-right" data-aos-once="false" data-aos-delay="200" data-aos-duration="1200" data-aos-easing="ease-out-cubic">
						<StaticImage
							src="../../../assets/images/homepage/threesections/Machine-Person-device-C.png"
							width={650}
							backgroundcolor={"#8955C6"}
							alt="PCR lab instrument down to the palm of your hand"
							className="illustration"
						/>
						<div className="grid-text feet">5 feet</div>
						<div className="grid-text inches">&lt;5 inches</div>
					</div>
				</StyledContainer>
				<StyledContainer className="column lightPurple">
					<h2>The biggest improvements are often the smallest.</h2>
					<p>
						Visby Medical shrank the traditional sofa-sized PCR lab-instrument down to the palm of your hand. Now it’s portable, 
						and easily deployable, in a 5" by 2" device. Revolutionary.
					</p>
				</StyledContainer>
			</StyledColoredSection>
			<StyledSection key="section3" className="parallaxBg2">
				<StyledContainer className="leftBox">
						<h2>Small and mighty fast.</h2>
						<p>
							Capable of identifying multiple pathogens from the same sample, the miniaturized Visby Medical PCR device delivers 
							accuracy just like central lab instruments, only thousand times smaller and faster. That’s huge.</p>
					</StyledContainer>
			</StyledSection>
			<StyledColoredSection key="section4">
				<StyledContainer className="column darkPurple">
					<div className="grid-container-bottom">
						<StaticImage
							src="../../../assets/images/homepage/threesections/cage-line.png"
							width={400}
							backgroundcolor={"#8955C6"}
							alt="Opened bird cage"
							className="illustration-bottom cage"
						/>
						<div data-aos="slide-right" data-aos-once="false" data-aos-delay="200" data-aos-duration="1200" data-aos-easing="ease-out-cubic">
							<StaticImage
								src="../../../assets/images/homepage/threesections/bird.png"
								width={50}
								backgroundcolor={"#8955C6"}
								alt="Bird flying free"
								className="iillustration-bottom bird"
							/>
						</div>
					</div>
				</StyledContainer>
				<StyledContainer className="column lightPurple">
					<h2>Surge capacity, without capital investment or maintenance.</h2>
					<p>
						Is it instrument-free or is it cartridge-free? It’s both, because it’s all-in-one, single-use, and disposable.
						Add more tests as your testing demand increases. Scale back as patient demand subsides.
						Order today and begin testing as soon as tomorrow.<sup>2</sup></p>
					<p className="subWhite">2. Depending on shipping.</p>
				</StyledContainer>
			</StyledColoredSection>
		</StyledThreeSections>
	)
}

export default ThreeSections
