import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"


const StyledMedicalCompany = styled.section`
  position: relative;
  border-bottom-width: 0;
  text-align: center;
  padding-bottom: 60px;
  
  ${breakpoint.small`
	text-align: left;
  `}

	${breakpoint.medium`
		padding: 30px 0 60px;
	`}
				
  ${Container} {
    padding: 0 10px;
    border: none !important;

	${breakpoint.medium`
		padding: 0 28px;
	`}
  }

	h2 {
		text-align: center;
		margin: 0 auto;
		padding: 4rem 0 1rem;

		  ${breakpoint.medium`
				padding: 4rem 0 2rem;
		  `}
	}

	.content {
		margin: 0 auto;
		column-count: 0;
		text-align: center;
		padding: 0 30px;

		p { 
			margin: 0 0 1rem;
		}
	 } 	   

	 .sub {
		font-style: italic;
		margin-top: 10px;
		line-height: 1.1em;
		font-size: x-small;
		color: ${colors.graySelect};

		${breakpoint.medium`
		  font-size: small;
		`}
	}

`

const MedicalCompany = () => {
	return (
		<StyledMedicalCompany>
			<Container>
				<h2 data-aos="fade-up" data-aos-once="false">Medical Company<br />with a Human Focus</h2>
				<div className="content" data-aos="fade-up" data-aos-once="false" data-aos-delay="150">
					<p>
						As a molecular diagnostics company, we recognize that there is always an anxious patient waiting. For that reason, we created highly accurate, easy-to-run, rapid PCR testing kits to help contain and control the spread of dangerous pathogens fast.
					</p>
					<p>
						Our aim is to continue developing testing devices that are reliably accurate. The current lack of rapid PCR testing available to inform a timely and accurate diagnosis for the patient demonstrates the inherent need for the Visby Medical diagnostic platform.
					</p>
				</div>
			</Container>
		</StyledMedicalCompany>
	)
}

export default MedicalCompany
