import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

const StyledContainer = styled(Container)`
  max-width: 1100px;
  padding: 80px 30px;

  ${breakpoint.small`
  	padding: 80px 60px;
  `}
  
  `

const StyledWhatIsSection = styled.section`
	background-color: ${colors.purpleDark};
	text-align: center;

    h2 {
      color: ${colors.white};
      padding-bottom: 2rem;
    }
  
    p {
      color: ${colors.white};
    }

`


const WhatIs = () => {
	return (
		<StyledWhatIsSection>
			<StyledContainer data-aos="fade-up" data-aos-once="false">
					<h2>What is Visby PCR?</h2>
					<p>
						It’s a full 40-cycle PCR lab in an incredibly small package. It’s also fast, creating a whole new category at the intersection of accuracy,
						speed and deployability. That’s a powerful new force that delivers a new era in results-driven diagnosis and informed
						treatment, during the patient visit.
					</p>
			</StyledContainer>
		</StyledWhatIsSection>
	)
}
export default WhatIs