import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"
import { ButtonLink } from "components/buttons"


const StyledContainer = styled(Container)`
  max-width: 1200px;
  background-color: ${colors.purpleDark};
  margin: 0px auto;
  padding: 0px;
  min-height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledCTA = styled.section`
  position: relative;
  text-align: center;
  background-color: ${colors.purpleDark};

	.heading {
		text-align: center;
		padding: 0px 20px;
		max-width: 95%;

	  ${breakpoint.medium`
			max-width: 75%;
	  `}
	
	h2 { 
		color: ${colors.white};
	}
    
   ${ButtonLink} {
    width: 196px;
    margin-top: 2rem;
    }
  }
 
`

const CTA = () => {
  return (
    <StyledCTA>
      <StyledContainer>
        <div className="heading">
          <h2 data-aos="fade-up" data-aos-once="false">Are you ready to bring the power of lab PCR to your point of care clinic?</h2>
          <ButtonLink backgroundcolor="primary" to="/how-to-buy/" data-aos="fade-up" data-aos-once="false" data-aos-delay="100">How to Buy</ButtonLink>
        </div>
      </StyledContainer>
    </StyledCTA>
  )
}
export default CTA
