import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Link } from "gatsby"

// Utils
import { colors } from "utils/variables/"
import breakpoint from "utils/breakpoints/"

// BG
import HeroBg from "assets/images/homepage/hero/background-purple.png"

const StyledHero = styled.header`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  border-bottom-width: 0;
  overflow: hidden;
  background: url(${HeroBg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  justify-content: center;
  max-width: 1500px;
  margin: 0 auto;

  ${breakpoint.small`
    flex-wrap: wrap;
    flex-direction: row;
  `}

  ${breakpoint.medium`
    margin-bottom: 60px;
  `}

  .imageBlock {
    width: 100%;
    height: 100%;
    position: relative;
    margin-top: 30px;

    ${breakpoint.xs`
    max-width: 700px;
    margin-top: 0;
  `}

    ${breakpoint.small`
    min-width: 500px;
    max-width: 600px;
    padding: 0;
  `}

    ${breakpoint.medium`
      min-width: 600px;
      max-width: 800px;
      padding: 0;
    `}
  }

  .textBlock {
    padding: 50px 15px 0;
    text-align: center;
    margin-top: 40px;
    width: 100%;

    ${breakpoint.small`
      padding: 50px 15px 20px;
    `}

    h1 { 
      font-size: 50px;
      margin-bottom: 1rem;
      color: ${colors.white};
      font-weight: 400!important;
      line-height: 1.1em;
      text-align: center;

      ${breakpoint.medium`
        font-size: 55px;
      `}

      ${breakpoint.large`
        font-size: 62px;
      `}
    }

    p { 
      color: ${colors.white};
      font-size: 18px;
      line-height: 1.2em;
      margin-block: 1.6rem;
      text-align: center;

      ${breakpoint.medium`
        font-size: 20px;
      `}

      ${breakpoint.large`
        font-size: 22px;
      `}
    }
  }

.column1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column2 {
  display: flex;
  flex-direction: column;

  ${breakpoint.small`
    flex-direction: row;
  `}
}

`

const Hero = () => {
  return (
    <StyledHero>
      <div className="column1">
        <div className="textBlock">
          <h1 data-aos="fade-up">The power of PCR<br />in your hands</h1>
          <p data-aos="fade-up" data-aos-delay="100">Results delivered at the point of care in under 30 minutes</p>
        </div>
      </div>
      <div className="column2">
        <div className="imageBlock">
          <Link to="/sexual-health-test/">
            <StaticImage
              src="../../../assets/images/homepage/hero/sexual-health-test.png"
              width={800}
              alt="Visby Medical Sexual Health Test"
            />
          </Link>
        </div>
        <div className="imageBlock">
          <Link to="/respiratory-health-test/">
            <StaticImage
              src="../../../assets/images/homepage/hero/respiratory-health-test.png"
              width={800}
              alt="Visby Medical Respiratory Health Test"
            />
          </Link>
        </div>
      </div>
    </StyledHero>
  )
}

export default Hero
