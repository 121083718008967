import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import { ButtonLink } from "components/buttons"


const StyledModalPopup = styled.section`

.popup{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.6);
    z-index:999;
    display: ${props => (props.showModal ? "flex" : "none")};
}

.popup-content{
    width: 100%;
    background: ${colors.white};
    margin: auto;
    position: relative;
    max-width: 700px;
    max-height: 800px;
	padding: 0;
    text-align: center;
    box-shadow: 10px 10px 10px rgba(0,0,0,0.3);
    
	  ${breakpoint.small`
			text-align: left;
			padding: 20px 0 0 50px;
	  `}
	  ${breakpoint.medium`
			max-width: 800px;
	  `}
    
	p {
		margin: 1rem 0;
		padding-inline: 20px;

	  ${breakpoint.small`
		 font-size: larger;
		 margin: 2rem 0;
		 padding-inline: 0;
	  `}
	}
				
	h2 {
		font-weight: 400;
		font-size: 28px;
		margin-bottom: 20px;

	  ${breakpoint.medium`
		 font-size: 32px;
	  `}
	}

	h2.titlePopUp {
	  padding-top: 25px;
      font-size: 36px;
      font-weight: 700;
      letter-spacing: -1px;
      color: ${colors.charcoal};
      margin-bottom: 0;

	  ${breakpoint.small`
		padding-top: 10px;
	  `}

      ${breakpoint.medium`
        font-size: 46px;
      `}
	}
}

	.closebox {
		margin-bottom: 35px;
	}
	
	.closebtn {
		position: absolute;
		top: 0;
		right: 15px;
		padding: 5px;
		width: 32px;
		height: 32px;
		justify-content: center;
		opacity: 1;
		cursor: pointer;

	  ${breakpoint.small`
		top: 5px;
		padding: 10px;
	  `}
	}
	
	.closebtn:hover {
	  opacity: 0.3;
	  transition: all 0.3s ease-out 0s;
	}
	.closebtn:before, .closebtn:after {
	  position: absolute;
	  content: ' ';
	  height: 33px;
	  width: 1px;
	  background-color: ${colors.charcoal};
	}
	.closebtn:before {
	  transform: rotate(45deg);
	}
	.closebtn:after {
	  transform: rotate(-45deg);
	}

	.devicepop {
		margin: 0 auto;
		width: 65%;
		margin-bottom: 0px;
		bottom: 0;
		
	  ${breakpoint.small`
			width: 100%;
			height: 390px;
	  `}
	}
	
	.row {
		display: flex;
		flex-direction: column;
		width: 100%!important;
		
	  ${breakpoint.small`
		 flex-direction: row;
	  `}
	  
	}
	
	.leftCol, .rightCol {
		width: 100%;
		
	  ${breakpoint.small`
		 width: 50%;
	  `}
	
		p > b {
			color: ${colors.purpleDark};
		}

	}
	
	    ${ButtonLink} {
        width: auto;
    }


`

const ModalPopup = props => {
	return (
		<StyledModalPopup
			showModal={props.showModal}
			localSessionCurrent={props.localSessionCurrent}
		>
			<div id="popup1" className="popup" onClick={props.onClick} aria-hidden="true">
				<div className="popup-content">
					<div className="closebox">
						<button className="closebtn" aria-label="close box" onClick={props.onClick}></button>
					</div>
					<h2 className="titlePopUp">Come see us in Boston!</h2>
					<div className="row">
						<div className="leftCol">
							<p>We'll be exhibiting at the <b>ACEP21&nbsp;Scientific&nbsp;Assembly</b> at the Boston&nbsp;Convention and&nbsp;Exhibition&nbsp;Center.<br />
								<b>October 25-27</b></p>
							<h2>Booth <b>#2521</b></h2>
							<ButtonLink backgroundcolor="orange" to="/news/acep21/">Visit our Virtual Booth</ButtonLink>
						</div>
						<div className="rightCol" data-aos="fade">
							<StaticImage
								src="../../../assets/images/homepage/popup/hand-sti-device-new.png"
								width={400}
								alt="Sexual Health Test"
								className="devicepop"
								style={{ backgroundSize: "contain", backgroundPosition: "bottom right" }}
							/>
						</div>
					</div>
				</div>
			</div>
		</StyledModalPopup>
	)
}

class BreakingNews extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			showModal: false,
			isOnlyOnce: false,
			lastScrollPosition: 0,
			localSessionCurrent: null
		}
	}

	componentDidMount = () => {

		const currentDate = new Date()
		const from = new Date("2021-04-19T00:00:00.000-04:00")
		const to = new Date("2021-10-27T19:00:00.000-04:00")

		if (currentDate >= from && currentDate <= to) {
			this.setState({
				isOnlyOnce: false
			})
		} else {
			this.setState({
				isOnlyOnce: true
			})
		}

		localStorage.removeItem("beginSession")
		localStorage.removeItem("endSession")

		let beginSessionStorage = "beginACEP21"
		let endSessionStorage = "endACEP21"

		window.addEventListener("scroll", this.handleScroll)

		const beginSession = localStorage.getItem(beginSessionStorage)
		const timeDelay = 6 * 60 * 60 * 1000
		this.setState({
			localSessionCurrent: beginSession
		})

		if (beginSession == null) {
			localStorage.setItem(beginSessionStorage, new Date().getTime())
			localStorage.setItem(endSessionStorage, new Date().getTime() + timeDelay)
		}

		if (new Date().getTime() >= localStorage.getItem(endSessionStorage)) {
			localStorage.removeItem(beginSessionStorage)
			localStorage.removeItem(endSessionStorage)
			this.setState({
				localSessionCurrent: localStorage.getItem(beginSessionStorage)
			})
			localStorage.setItem(beginSessionStorage, new Date().getTime())
			localStorage.setItem(endSessionStorage, new Date().getTime() + timeDelay)
		}
	}

	componentWillUnmount = () => {
		window.removeEventListener("scroll", this.handleScroll)
	}

	handleScroll = () => {
		const scrollPosition = window.scrollY

		if (
			scrollPosition > this.state.lastScrollPosition &&
			!this.state.isOnlyOnce &&
			!this.state.localSessionCurrent &&
			this.state.lastScrollPosition > 600
		) {

			if (!this.state.showModal) {
				this.setState({
					showModal: true,
					isOnlyOnce: true
				})
				document.querySelector("html").classList.add("no-scroll")
				document.querySelector("body").classList.add("no-scroll")
			}
		}

		this.setState({
			lastScrollPosition: scrollPosition,
		})
	}

	hideModal = () => {
		if (this.state.showModal && !this.state.localSessionCurrent) {
			this.setState({
				showModal: false
			},
				() => {
					document.querySelector("html").classList.remove("no-scroll")
					document.querySelector("body").classList.remove("no-scroll")
				}
			)
		} else {
			this.setState({
				showModal: true
			},
				() => {
					document.querySelector("html").classList.add("no-scroll")
					document.querySelector("body").classList.add("no-scroll")
				}
			)
		}
	}

	render = () => (
		<ModalPopup
			showModal={this.state.showModal}
			localSessionCurrent={this.state.localSessionCurrent}
			onClick={this.hideModal}
		/>
	)
}

export default BreakingNews

