import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import BreakingNews from "./screens/homepage/breaking-news"
import Hero from "./screens/homepage/hero"
import SideBySide from "./screens/homepage/side-by-side"
import TopIcons from "./screens/homepage/top-icons"
import MedicalCompany from "./screens/homepage/medical-company"
import ThreeSections from "./screens/homepage/three-sections"
import Cta from "./screens/homepage/cta"
import HighPraise from "./screens/homepage/high-praise"
import Testimonials from "./screens/homepage/testimonials"
import WhatIs from "./screens/homepage/what-is"
import InTheMedia from "./screens/homepage/in-the-media"

const HomePage = () => {
  const showModal = false;
  return (
    <React.Fragment>
      { showModal &&  <BreakingNews /> }
      <Hero />
      <SideBySide />
      <TopIcons />
      <WhatIs />
      <MedicalCompany />
      <ThreeSections />
      <HighPraise />
      <Cta />
      <Testimonials />
      <InTheMedia />
    </React.Fragment>
  )
}
export default HomePage

export const Head = () => (
  <Seo
  title="The Only Instrument-Free, Rapid PCR Test"
  description="Bring the power of the lab right to the point of need. Our instrument-free rapid, single-use PCR test may be used at any site with a CLIA certificate of waiver."
  image="/meta/visby-medical.jpg"
/>
)
